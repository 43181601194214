define("discourse/plugins/discourse-pushover-notifications/discourse/lib/pushover-notifications", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subscribe = subscribe;
  _exports.unsubscribe = unsubscribe;
  function subscribe(subscription) {
    return (0, _ajax.ajax)("/pushover_notifications/subscribe", {
      type: "POST",
      data: {
        subscription
      }
    });
  }
  function unsubscribe() {
    return (0, _ajax.ajax)("/pushover_notifications/unsubscribe", {
      type: "POST"
    });
  }
});