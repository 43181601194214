define("discourse/plugins/discourse-pushover-notifications/discourse/components/pushover-notification-config", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/plugins/discourse-pushover-notifications/discourse/lib/pushover-notifications"], function (_exports, _component, _computed, _decorators, _pushoverNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    showPushoverNotification() {
      return this.siteSettings.pushover_notifications_enabled;
    },
    has_subscription: (0, _computed.empty)("subscription"),
    disabled: (0, _computed.or)("has_subscription", "loading"),
    loading: false,
    errorMessage: null,
    calculateSubscribed() {
      this.set("pushoverNotificationSubscribed", this.currentUser.custom_fields.discourse_pushover_notifications != null);
    },
    pushoverNotificationSubscribed: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        pushoverNotificationSubscribed: this.currentUser.custom_fields.discourse_pushover_notifications != null,
        errorMessage: null
      });
    },
    actions: {
      subscribe() {
        this.setProperties({
          loading: true,
          errorMessage: null
        });
        (0, _pushoverNotifications.subscribe)(this.subscription).then(response => {
          if (response.success) {
            this.currentUser.custom_fields.discourse_pushover_notifications = this.subscription;
            this.calculateSubscribed();
          } else {
            this.set("errorMessage", response.error);
          }
        }).finally(() => this.set("loading", false));
      },
      unsubscribe() {
        this.setProperties({
          loading: true,
          errorMessage: null
        });
        (0, _pushoverNotifications.unsubscribe)().then(response => {
          if (response.success) {
            this.currentUser.custom_fields.discourse_pushover_notifications = null;
            this.calculateSubscribed();
          } else {
            this.set("errorMessage", response.error);
          }
        }).finally(() => this.set("loading", false));
      }
    }
  }, [["method", "showPushoverNotification", [_decorators.default]]]));
});